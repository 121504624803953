var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"page-container"},[_c('page-title',{attrs:{"title":_vm.$t('organisation.overviewTitle')}}),_c('organisation-filter'),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('organisation-avatar',{attrs:{"organisation":item,"size":_vm.enums.AvatarSize.Small}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":_vm.getStatusType(item.organisationStatus)}},[_vm._v(" "+_vm._s(_vm.$t( ("organisation.verified.status." + (item.organisationStatus.toLowerCase())) ))+" ")])]}},{key:"item.registeredAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("toUnixTime")(item.createdAt)))+" ")]}},{key:"item.validatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.validatedAt))+" ")]}},{key:"item.transactionsLink",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.showTransactions(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('common.label.transaction'))+" ")])]}},{key:"item.dashboardLink",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDashboard(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chart-donut")]),_vm._v(" "+_vm._s(_vm.$t('common.label.dashboard'))+" ")],1)]}}])}),(_vm.tableDataNext)?_c('load-more-button',{attrs:{"is-loading":_vm.isLoadingNext},on:{"click":function($event){return _vm.fetchOrganisations(_vm.tableDataNext)}}},[_vm._v(" "+_vm._s(_vm.$t('common.moreButton'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }