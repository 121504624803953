





























































import OrganisationFilter from '@/components/organisation/OrganisationFilter.vue'
import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import { OrganisationStatusMixin } from '@/mixins/OrganisationStatusMixin'
import { mapGetters, mapActions } from 'vuex'
import { PageTitle } from '@/components/atoms/PageTitle'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'Organisations',
  components: {
    PageTitle,
    OrganisationAvatar,
    OrganisationFilter,
    LoadMoreButton,
  },
  mixins: [OrganisationStatusMixin],
  inject: ['enums'],
  computed: {
    ...mapGetters('organisation', {
      organisations: 'getOrganisations',
      tableDataNext: 'getTableDataNext',
      isLoadingNext: 'getIsLoadingNext',
      isLoading: 'getIsLoading',
    }),
    tableItems() {
      return this.organisations ?? []
    },
    tableHeaders() {
      return [
        { text: '', value: 'avatar', width: 70, sortable: false },
        {
          text: this.$t('common.status'),
          value: 'status',
          width: 120,
          sortable: false,
        },
        { text: this.$t('common.form.name.label'), value: 'name' },
        {
          text: this.$t('organisation.customerNumber'),
          value: 'customerNumber',
        },
        {
          text: this.$t('common.form.description.label'),
          value: 'description',
          sortable: false,
          width: 350,
        },
        {
          text: this.$t('organisation.registeredAt'),
          value: 'registeredAt',
          sortable: false,
        },
        {
          text: this.$t('organisation.validatedAt'),
          value: 'validatedAt',
          sortable: false,
        },
        { text: '', value: 'transactionsLink', width: 170, sortable: false },
        { text: '', value: 'dashboardLink', width: 170, sortable: false },
      ]
    },
  },
  methods: {
    ...mapActions('organisation', ['fetchOrganisations']),
    showTransactions(organisationId: string) {
      this.$router.push({
        name: 'organisationTransactions',
        params: { id: organisationId },
      })
    },
    showDashboard(organisationId: string) {
      this.$router.push({
        name: 'organisationDashboard',
        params: { id: organisationId },
      })
    },
    onRowClick(row: any) {
      this.$router.push({ name: 'organisation', params: { id: row.id } })
    },
  },
}
