















import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import { IOrganisationFilters } from '@/interfaces'
import { OrganisationStatus } from '@/enums'
import { Dropdown } from '@/components/molecules/Dropdown'

const dropdownValues = [
  OrganisationStatus.Ready,
  OrganisationStatus.Draft,
  OrganisationStatus.Validated,
  OrganisationStatus.Disabled,
  OrganisationStatus.Restricted,
]

export default defineComponent({
  name: 'OrganisationsFilter',
  components: { Dropdown },
  data() {
    return {
      dropdownItems: dropdownValues.map((item) => ({
        text: this.$t(`organisation.verified.status.${item.toLowerCase()}`),
        value: item,
      })),
    }
  },
  computed: {
    ...mapGetters('organisation', {
      filters: 'getFiltersFromQuery',
    }),
  },
  watch: {
    $route() {
      this.updateOrganisations(this.$route.query)
    },
  },
  async created() {
    await this.updateOrganisations(this.$route.query)
  },
  methods: {
    ...mapActions('organisation', [
      'fetchOrganisations',
      'fetchFiltersFromQuery',
    ]),
    async updateOrganisations(query: IOrganisationFilters) {
      await this.fetchFiltersFromQuery(query)
      await this.fetchOrganisations()
    },
    async updateQuery(status: string) {
      const query = { status }

      await this.$router.push({
        query,
      })
    },
  },
})
