







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isDistributed: {
      type: Boolean,
      default: true,
    },
  },
})
