export const OrganisationStatusMixin = {
  methods: {
    getStatusType(status: string) {
      const {
        Disabled,
        Draft,
        Ready,
        Validated,
        Restricted,
      } = this.enums.OrganisationStatus

      switch (status) {
        case Disabled:
        case Restricted:
          return 'warning'
        case Draft:
        case Ready:
          return 'info'
        case Validated:
          return 'success'
        default:
          return ''
      }
    },
  },
}
